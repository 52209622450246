import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'src/store/components/axiosInstance';
import createDataSlice from 'src/store/components/createDataSlice';
import handleError from 'src/store/components/handleError';

const modelName = 'Dropdown';
const tableName = 'dropdown';
const pathName = 'drop-down';

export const fetchRecords = createAsyncThunk(
  `${modelName}s/fetch${pathName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(pathName, { params });
      console.log("response",response)
      return { data: response.data.data.data, table_name: params.table_name };
    } catch (error) {
      handleError(error);
    }
  }
);

const initialState = {
  properties: [],
  parks: [],
  individuals: [],
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false,
};

const extraReducers = (builder) => {
  // *** FETCH DROPDOWN CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    const { data, table_name } = action.payload;

    if (state[table_name] !== undefined) {
        state[table_name] = data;
      }    

    state.loading = false; // Set loading false to hide loading screen
  });

  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`;
    state.loading = false; // Set loading false to hide loading screen
  });
};

export const dataSlice = createDataSlice(
  tableName,
  extraReducers,
  initialState
);
export default dataSlice.reducer;
