import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './css/bootstrap.min.css';
import './css/font-icons.css';
import './css/fonts.css';
// import "./css/nouislider.min.css"
import './css/swiper-bundle.min.css';
import './css/animate.css';
import './css/styles.css';
import 'src/css/custom.css';
import { Provider } from 'react-redux';
import { store } from 'src/store';

const LazyApp = lazy(() => import('./App'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <LazyApp />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
