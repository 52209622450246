import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'src/store/components/axiosInstance';
import createDataSlice from 'src/store/components/createDataSlice';
import handleError from 'src/store/components/handleError';
import saveToRedux from 'src/store/components/saveToRedux';

const modelName = 'insurances';
const tableName = 'insurances';
const pathName = 'insurances';

// ** Fetch Records
export const fetchRecords = createAsyncThunk(
  `${modelName}s/fetch${modelName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(pathName, params);
      return response.data.data;
    } catch (error) {
      handleError(error);
    }
  }
);

// ** Add Record
export const addRecord = createAsyncThunk(
  `${modelName}s/add${modelName}`,
  async (data, { getState, dispatch }) => {
    try {
      const response = await axiosInstance.post(`${pathName}`, data);
      dispatch(fetchRecords(getState().insurances?.params));
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }
);

// ** find Record
export const findRecord = createAsyncThunk(
  `${modelName}s/find${modelName}`,
  async (id, { getState, dispatch }) => {
    try {
      const response = await axiosInstance.get(`${pathName}/${id}`);
      return response.data;
    } catch (error) {
      handleError(error, 500);
    }
  }
);

// ** Update Record
export const updateRecord = createAsyncThunk(
  `${modelName}s/update${modelName}`,
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${pathName}/${data.get('id')}`,
        data
      );
      dispatch(fetchRecords(getState().insurances?.params));
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }
);

// ** Delete Record
export const deleteRecord = createAsyncThunk(
  `${modelName}s/delete${modelName}`,
  async (id, { getState, dispatch }) => {
    try {
      await axiosInstance.delete(`${pathName}/${id}`);
      dispatch(fetchRecords(getState().insurances?.params));
      return id;
    } catch (error) {
      handleError(error, 500);
    }
  }
);

const initialState = {
  data: [], // Array to store data
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false,
};

const extraReducers = (builder) => {
  // *** FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload); // Saving districts to redux
    state.loading = false; // Set loading false to hide loading screen
  });

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`;
    state.loading = false; // Set loading false to hide loading screen
  });

  // *** ADD CASES
  // API fetching state
  builder.addCase(addRecord.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });

  // API response received state (SUCCESS)
  builder.addCase(addRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload); // Saving data to redux
    state.loading = false; // Set loading false to hide loading screen
  });

  // API response received state (FAILURE)
  builder.addCase(addRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to add ${modelName}!`;
    state.loading = false; // Set loading false to hide loading screen
  });

  // *** find CASES
  // API finding state
  builder.addCase(findRecord.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });

  // API response received state (SUCCESS)
  builder.addCase(findRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload); // Saving data to redux
    state.loading = false; // Set loading false to hide loading screen
  });

  // API response received state (FAILURE)
  builder.addCase(findRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to find ${modelName}s!`;
    state.loading = false; // Set loading false to hide loading screen
  });

  // *** UPDATE CASES
  // API fetching state
  builder.addCase(updateRecord.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });

  // API response received state (SUCCESS)
  builder.addCase(updateRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload); // Saving data to redux
    state.loading = false; // Set loading false to hide loading screen
  });

  // API response received state (FAILURE)
  builder.addCase(updateRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to update ${modelName}!`;
    state.loading = false; // Set loading false to hide loading screen
  });

  // *** DELETE CASES
  // API fetching state
  builder.addCase(deleteRecord.pending, (state) => {
    state.loading = true; // Set loading true to show loading screen
  });

  // API response received state (SUCCESS)
  builder.addCase(deleteRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    state.loading = false; // Set loading false to hide loading screen
    state.roles = state.insurances?.filter((data) => data.id !== action.payload);
  });

  // API response received state (FAILURE)
  builder.addCase(deleteRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to delete ${modelName}!`;
    state.loading = false; // Set loading false to hide loading screen
  });
};

export const dataSlice = createDataSlice(
  tableName,
  extraReducers,
  initialState
);
export default dataSlice.reducer;
