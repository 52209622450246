import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'src/store/components/axiosInstance';
import createDataSlice from 'src/store/components/createDataSlice';
import saveToRedux from 'src/store/components/saveToRedux';

const modelName = 'Blog';
const tableName = 'blogs';
const pathName = 'dynamic-pages';

export const fetchRecords = createAsyncThunk(
    `${modelName}s/fetch${pathName}s`,
    async (params, { rejectWithValue }) => {
        const response = await axiosInstance.get(pathName + '?page_type=0', params);
        return response.data.data;
    }
);

export const findRecords = createAsyncThunk(
    `${modelName}s/find${pathName}s`,
    async (id, { rejectWithValue }) => {
        const response = await axiosInstance.get(pathName + `/${id}`);
        // console.log('Response data', response.data);
        return response.data;
    }
);

const initialState = {
    data: [], // Array to store data
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false,
};

const extraReducers = (builder) => {
    // *** FETCH CASES
    // API fetching state
    builder.addCase(fetchRecords.pending, (state) => {
        state.loading = true; // Set loading true to show loading screen
    });

    // API response received state (SUCCESS)
    builder.addCase(fetchRecords.fulfilled, (state, action) => {
        // Assuming action.payload is the response from the API
        saveToRedux(state, action.payload); // Saving data to redux
        state.loading = false; // Set loading false to hide loading screen
    });

    // API response received state (FAILURE)
    builder.addCase(fetchRecords.rejected, (state, action) => {
        state.message = action.payload?.message || `Failed to fetch ${modelName}s!`;
        state.loading = false; // Set loading false to hide loading screen
    });

    // API fetching state
    builder.addCase(findRecords.pending, (state) => {
        state.loading = true; // Set loading true to show loading screen
    });

    // API response received state (SUCCESS)
    builder.addCase(findRecords.fulfilled, (state, action) => {
        // Assuming action.payload is the response from the API
        saveToRedux(state, action.payload); // Saving data to redux
        state.loading = false; // Set loading false to hide loading screen
    });

    // API response received state (FAILURE)
    builder.addCase(findRecords.rejected, (state, action) => {
        state.message = action.payload?.message || `Failed to fetch ${modelName}s!`;
        state.loading = false; // Set loading false to hide loading screen
    });
};

export const dataSlice = createDataSlice(
    tableName,
    extraReducers,
    initialState
);
export default dataSlice.reducer;
